import React from "react"
import { Div, Text, Input, Image } from "atomize"

import Layout from "../../components/common/Layout"
import CardHeader from "../../components/common/CardHeader"
import ResetPasswordForm from "../../components/auth/ResetPasswordForm"
import WebsiteContainer from "../../components/common/atoms/WebsiteContainer"

const ResetPassword = ({ data }) => {
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <>
      <Layout site={site}>
        <WebsiteContainer>
          <Div m="2.5rem auto" maxW="30rem">
            <CardHeader heading="profile" image="/disk.svg"/>
            <Div bg="yellow" p="1.875rem">
              <Div border={{ b: "1px solid" }} borderColor="borderColor">
                <Text
                  textTransform="uppercase"
                  textWeight="bold"
                  textSize="display1"
                  style={{ lineHeight: "100%" }}
                  fontFamily="secondary"
                >
                  Reset Password
                </Text>
                <Text style={{ lineHeight: "150%" }} m={{ y: "1.25rem" }}>
                  Choose something you’ll remember!
                </Text>
              </Div>

              <ResetPasswordForm />
            </Div>
          </Div>
        </WebsiteContainer>
      </Layout>
    </>
  )
}

export default ResetPassword

export const query = graphql`
  query ResetPasswordQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`
